/** @jsx jsx */
import React from 'react';
import { jsx } from 'theme-ui';

const showTopBar =
  process.env.GATSBY_SHOW_BANNER === 'true' ||
  process.env.GATSBY_SHOW_BANNER === true;

const TopBar = () => {
  if (!showTopBar) {
    return null;
  }
  return (
    <div
      sx={{
        backgroundColor: 'primary',
        color: 'background',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        p: 2,
        textTransform: 'uppercase',
        position: ['absolute', 'fixed'],
        left: 0,
        right: 0,
        zIndex: 2000,
        fontSize: [2, 3],
      }}
    >
      {process.env.GATSBY_BANNER_TEXT}
    </div>
  );
};

export default TopBar;
