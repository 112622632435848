import React, { createContext, useState, useEffect } from 'react';

import client from '../client';

const defaultValues = {
  isCartOpen: false,
  toggleCartOpen: () => {},
  cart: [],
  addProductToCart: () => {},
  removeProductFromCart: () => {},
  checkCoupon: () => {},
  client,
  checkout: {
    lineItems: [],
  },
};

export const StoreContext = createContext(defaultValues);

export const StoreProvider = ({ children }) => {
  const [checkout, setCheckout] = useState(defaultValues.checkout);
  const [isCartOpen, setCartOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const toggleCartOpen = () => {
    setCartOpen(!isCartOpen);
  };

  // Check it its a browser
  const isBrowser = typeof window !== 'undefined';

  useEffect(() => {
    initializeCheckout();
  }, []);

  const getNewId = async () => {
    try {
      const newCheckout = await client.checkout.create();
      if (isBrowser) {
        localStorage.setItem('checkout_id', newCheckout.id);
      }
      return newCheckout;
    } catch (error) {
      console.error(error);
    }
  };

  const initializeCheckout = async () => {
    // Check if checkout id exists
    const currentCheckoutId = isBrowser
      ? localStorage.getItem('checkout_id')
      : null;
    let newCheckout = null;
    if (currentCheckoutId) {
      // If id exists, fetch checkout from Shopify
      try {
        newCheckout = await client.checkout.fetch(currentCheckoutId);
        if (newCheckout === null) {
          newCheckout = await getNewId();
        }
      } catch (e) {
        console.log('newCheckout Error ', e);
      }
      // if order was completed, clear and get a new id
      if (newCheckout && newCheckout.completedAt) {
        newCheckout = await getNewId();
      }
    } else {
      // If id does not exist, create a new checkout
      newCheckout = await getNewId();
    }

    // Set checkout to state
    setCheckout(newCheckout);
  };

  const addProductToCart = async (variantId, qty) => {
    try {
      setLoading(true);
      const lineItems = [
        {
          variantId,
          quantity: qty,
        },
      ];

      const newCheckout = await client.checkout.addLineItems(
        checkout.id,
        lineItems,
      );
      setCheckout(newCheckout);
      // console.log({ newCheckout });
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const removeProductFromCart = async lineItemId => {
    try {
      setLoading(true);
      const newCheckout = await client.checkout.removeLineItems(checkout.id, [
        lineItemId,
      ]);
      setCheckout(newCheckout);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const checkCoupon = async coupon => {
    setLoading(true);
    const newCheckout = await client.checkout.addDiscount(checkout.id, coupon);
    setCheckout(newCheckout);
    setLoading(false);
  };

  const removeCoupon = async coupon => {
    setLoading(true);
    const newCheckout = await client.checkout.removeDiscount(
      checkout.id,
      coupon,
    );
    setCheckout(newCheckout);
    setLoading(false);
  };

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addProductToCart,
        checkout,
        toggleCartOpen,
        isCartOpen,
        removeProductFromCart,
        checkCoupon,
        removeCoupon,
        isLoading,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};
