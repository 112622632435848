/** @jsx jsx */
import React, { useContext } from 'react';
import { jsx } from 'theme-ui';
import { useTransition, animated } from 'react-spring';
import { StoreContext } from '../context/StoreContext';
// import loadingGif from '../images/loading.gif';

const Loader = () => {
  const { isLoading } = useContext(StoreContext);

  const transitions = useTransition(isLoading, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  return transitions.map(({ item, key, props }) => {
    return (
      item && (
        <animated.div
          key={key}
          style={{
            position: 'fixed',
            left: 0,
            right: 0,
            bottom: 0,
            top: 0,
            zIndex: 4242,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,255,0.6)',
            ...props,
          }}
        >
          <p
            sx={{
              variant: 'styles.h1',
              color: 'white',
              fontWeight: 'bold',
            }}
          >
            LOADING
          </p>
        </animated.div>
      )
    );
  });
};

export default Loader;
