/** @jsx jsx */
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { jsx, useColorMode } from 'theme-ui';

// import '../style.scss';
import { StoreContext } from '../context/StoreContext';
import Loader from './Loader';
import TopBar from './TopBar';

const Header = () => {
  const { checkout } = useContext(StoreContext);
  const qty = checkout.lineItems.reduce(
    (total, item) => total + item.quantity,
    0,
  );

  const showTopBar = process.env.GATSBY_SHOW_BANNER === 'true';

  return (
    <>
      <TopBar />
      <header
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontFamily: 'body',
          justifyContent: ['space-around', 'flex-end'],
          position: ['absolute', 'fixed'],
          top: showTopBar ? '30px' : '0px',
          right: '0px',
          left: '0px',
          zIndex: '2000',
          py: 3,
          px: [3, 4],
          flexWrap: ['wrap', 'nowrap'],
          // backgroundColor: ['white', 'transparent'],
          // borderBottom: '3px solid black',
        }}
      >
        <div
          sx={{
            display: 'flex',
            flexBasis: ['100%', 'auto'],
            justifyContent: 'space-between',
          }}
        >
          <Link
            to="/"
            sx={{
              variant: 'styles.navlink',
              // fontWeight: 'bold',
            }}
          >
            DVLPR®
          </Link>
          <Link
            to="/cart"
            activeStyle={{ fontWeight: 'bold' }}
            sx={{
              variant: 'styles.navlink',
              display: ['flex', 'none'],
            }}
          >
            Cart {qty > 0 && `(${qty})`}
          </Link>
        </div>
        <div
          sx={{
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'center',
          }}
        >
          {/* <Link
            to="/"
            sx={{
              variant: 'styles.navlink',
              marginRight: '10px',
            }}
          >
            SHOP
          </Link> */}
          <Link
            to="/blog/"
            activeStyle={{ fontWeight: 'bold' }}
            partiallyActive
            sx={{
              variant: 'styles.navlink',
              marginRight: '1vw',
            }}
          >
            BLOG
          </Link>
          <Link
            to="/info/"
            activeStyle={{ fontWeight: 'bold' }}
            sx={{
              variant: 'styles.navlink',
              marginRight: '1vw',
            }}
          >
            FAQ
          </Link>
          <Link
            to="/about/"
            activeStyle={{ fontWeight: 'bold' }}
            sx={{
              variant: 'styles.navlink',
            }}
          >
            ABOUT
          </Link>
        </div>
        <div
          sx={{
            justifyContent: ['space-between', 'flex-end'],
            flexDirection: 'row',
            display: ['none', 'flex'],
          }}
        >
          <Link
            to="/cart"
            activeStyle={{ fontWeight: 'bold' }}
            sx={{
              variant: 'styles.navlink',
            }}
          >
            Cart {qty > 0 && `(${qty})`}
          </Link>
        </div>
      </header>

      <Loader />
    </>
  );
};

export default Header;
