import { tailwind } from '@theme-ui/presets';

export default {
  useCustomProperties: true,
  useColorSchemeMediaQuery: false,
  initialColorMode: 'light',
  breakpoints: [...tailwind.breakpoints],
  colors: {
    text: '#000',
    background: '#fff',
    primary: 'blue',
    secondary: '#119',
    muted: '#f6f6f6',
    highlight: '#efeffe', // '#ffffcc',
    gray: '#777',
    accent: '#609',
    darken: 'rgba(0, 0, 0, .25)',
    modes: {
      dark: {
        text: '#fff',
        background: '#060606',
        primary: '#ee01ff',
        secondary: '#e0f',
        muted: '#191919',
        highlight: '#29112c',
        gray: '#999',
        accent: '#c0f',
      },
    },
  },
  fonts: {
    body: 'Inter, sans-serif',
    heading: 'Inter, sans-serif',
  },
  layout: {
    container: {
      maxWidth: '1200px',
    },
  },
  buttons: {
    solid: {
      backgroundColor: 'primary',
      color: 'background',
      fontSize: '20px',
      fontFamily: 'body',
      px: 4,
      py: 2,
      textTransform: 'uppercase',
      cursor: 'pointer',
      textDecoration: 'none',
      border: 'none',
    },
  },
  styles: {
    heroDisplayText: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      m: 0,
      mb: 1,
      mt: 2,
      fontSize: [5, 7],
      color: ['background', 'primary'],
    },
    navLogo: {
      color: 'text',
      fontWeight: 'bold',
      fontFamily: 'body',
      fontSize: 6,
      textDecoration: 'none',
    },
    navlink: {
      color: 'text',
      textDecoration: 'none',
      textTransform: 'uppercase',
      fontWeight: 'body',
      fontFamily: 'body',
      fontSize: [3, 3, '2.2vw'],
      lineHeight: ['34px', '34px', '3vw'],
      background: 'none',
      border: 'none',
      px: 2,
      py: 1,
      margin: 0,
    },
    p: {
      fontSize: [2, 3],
      fontFamily: 'body',
      lineHeight: tailwind.lineHeights.relaxed,
    },
    blogDate: {
      fontSize: [1, 2],
      fontFamily: 'body',
      lineHeight: 'relaxed',
      fontStyle: 'italic',
    },
    img: {
      maxWidth: '100%',
      color: 'primary',
      boxShadow: '20px 20px 0 0',
      mb: 2,
    },
    a: {
      color: 'primary',
    },
    h1: {
      ...tailwind.styles.h1,
      textTransform: 'uppercase',
      fontSize: ['30px', '24px'],
      lineHeight: ['32px', '26px'],
      color: 'text',
      fontWeight: 'normal',
    },
    blogListTitle: {
      ...tailwind.styles.h2,
      marginBottom: 4,
      textTransform: 'uppercase',
      color: 'text',
      fontWeight: 'normal',
    },
    h2: {
      ...tailwind.styles.h2,
      // marginTop: 5,
      color: 'text',
      marginTop: 4,
      marginBottom: -2,
    },
    subHeader: {
      ...tailwind.styles.h2,
      textTransform: 'uppercase',
      fontSize: ['30px', '30px', '1.8vw'],
      lineHeight: ['32px', '32px', '2.5vw'],
      // marginTop: 5,
      color: 'text',
    },
    h3: {
      ...tailwind.styles.h3,
      fontWeight: 'normal',
      textTransform: 'uppercase',
    },
    blockquote: {
      ...tailwind.styles.blockquote,
      padding: '0 1em',
      color: '#6a737d',
      borderLeft: '3px solid black',
      margin: 0,
      marginBottom: 4,
    },
    placeholder: {
      textTransform: 'uppercase',
    },
  },
};
